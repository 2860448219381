<template>
  <div
    class="absolute top-0 left-0 w-full h-full bg-image"
    :class="[
      `obj-fit-${objectFit}`,
      `pos-${position}`,
      { 'fade-out': fadeOut },
    ]"
    :style="{
      '--bg-image': isSvg ? `url(${imgPath})` : undefined,
      '--bg-color': color.hex,
    }"
  >
    <div class="absolute top-0 left-0 w-full h-full"></div>
  </div>
</template>

<script setup lang="ts">
import { SectionBackgroundPatternStoryblok } from '@/types';
import { ISbPluginThemeColorPicker } from '@/types';

export interface Props {
  blok: SectionBackgroundPatternStoryblok;
  fadeOut?: boolean;
  objectFit?: 'repeat' | 'cover' | 'contain' | 'stretch';
  position?: 'top-center' | 'bottom-center' | 'bottom-left';
}
const { 
  blok, 
  fadeOut = true,
  objectFit = 'repeat',
  position = 'top-center'
} = defineProps<Props>();

const color = computed(() => blok.color as ISbPluginThemeColorPicker);

const isSvg = computed(() => {
  const svg = blok.image.filename.endsWith('.svg');
  if (!svg) console.warn('SbSectionBackgroundPattern: image is not an svg.');
  return svg;
});

const imgPath = computed(() =>
  // avoid cors issues
  blok.image.filename.replace('//a.storyblok.com/', '//a2.storyblok.com/'),
);
</script>

<style scoped lang="scss">
.bg-image {
  &.fade-out {
    // fade out from top to bottom
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    > div {
      // fade in during the top 200px
      mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0px,
        rgba(0, 0, 0, 1) 200px
      );
    }
  }
  > div::before {
    // set the background color and mask it with the image
    @apply absolute top-0 left-0 w-full h-full;
    content: '';
    background-color: var(--bg-color);
    mask-image: var(--bg-image);
    mask-repeat: repeat;
    mask-position: top center;
    mask-size: 100% auto;
  }

  &.obj-fit-cover {
    > div::before {
      mask-size: cover;
      mask-repeat: no-repeat;
    }
  }

  &.obj-fit-contain {
    > div::before {
      mask-size: contain;
      mask-repeat: no-repeat;
    }
  }

  &.obj-fit-stretch {
    > div::before {
      mask-size: 100% 100%;
      mask-repeat: no-repeat;
    }
  }

  &.pos-bottom-left {
    > div::before {
      mask-position: bottom left;
    }
  }

  &.pos-bottom-center {
    > div::before {
      mask-position: bottom center;
    }
  }
}
</style>
