<template>
  <div
    data-comp="SbModuleBackgroundPattern"
    class="absolute top-0 left-0 w-full h-full bg-image scale-101"
    :class="[
      `obj-fit-${objectFit}`,
      `pos-${position}`,
      { 'fade-out': fadeOut },
    ]"
    :style="{
      '--bg-image': isSvg ? `url(${imgPath})` : undefined,
    }"
  >
    <div class="absolute top-0 left-0 w-full h-full">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ModuleBackgroundPatternStoryblok } from '@/types';
import { ISbPluginThemeColorPicker } from '@/types';

export interface Props {
  blok: ModuleBackgroundPatternStoryblok;
  fadeOut?: boolean;
  objectFit?: 'repeat' | 'cover' | 'contain' | 'stretch';
  position?: 'top-center' | 'bottom-center' | 'bottom-left';
}
const { 
  blok, 
  fadeOut = true,
  objectFit = 'repeat',
  position = 'top-center'
} = defineProps<Props>();

const isSvg = computed(() => {
  const svg = blok.image.filename.endsWith('.svg');
  if (!svg) console.warn('SbModuleBackgroundPattern: image is not an svg.');
  return svg;
});

const imgPath = computed(() =>
  // avoid cors issues
  blok.image.filename.replace('//a.storyblok.com/', '//a2.storyblok.com/'),
);
</script>

<style scoped lang="scss">
.bg-image {
  > * {
    // set the background color and mask it with the image
    @apply absolute top-0 left-0 w-full h-full;
    content: '';
    // background-color: red;
    mask-image: var(--bg-image), linear-gradient(#fff 0 0);
    mask-repeat: repeat;
    mask-position: top center;
    mask-size: 100% auto;
    // inverting mask by adding white gradient and mask-compose
    // -webkit-mask-composite: destination-out;

    // -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    // mask-type: alpha;
  }

  &.obj-fit-cover {
    > * {
      mask-size: cover;
      mask-repeat: no-repeat;
    }
  }

  &.obj-fit-contain {
    > * {
      mask-size: contain;
      mask-repeat: no-repeat;
    }
  }

  &.obj-fit-stretch {
    > * {
      mask-size: 100% 100%;
      mask-repeat: no-repeat;
    }
  }

  &.pos-bottom-left {
    > * {
      mask-position: bottom left;
    }
  }

  &.pos-bottom-center {
    > * {
      mask-position: bottom center;
    }
  }
}
</style>
